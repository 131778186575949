<div class="flex-percent center middle container-height full-width overflow-hidden">
    <div class="flex-row center middle container-height full-width auth-container container-50">
        <div class="flex-column center">
            <div class="flex-row even">
                <mat-icon class="logo-icon" svgIcon="netralogin"></mat-icon>
            </div>
            <div>
                <login></login>
            </div>
            <div class="footer">
                <p>Copyright &copy; {{ currentYear }} Strayos</p>
            </div>
        </div>
    </div>
    <div class="right-half full-width container-height container-50">
        <img src="./../../../../assets/mat-icons/netraloginimg.webp" height="100%" width="100%" alt="Image" loading="lazy">
    </div>
</div>