import { Injectable } from '@angular/core';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { cleanFilename, downloadContent } from '@lib/dom/download';
import { MeasurementTypeToUnit } from '@lib/angular/measurement/measurement-type-to-unit';
import { measurement } from '@lib/measurement';
import { ExtendedTypeMeta } from '@lib/measurement/types';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { firstValueFrom } from 'rxjs';
import { fragQuery } from '@models';

@Injectable({
  providedIn: 'root',
})
export class CsvUtilService {
  constructor(
    private readonly fragQuery: FragQueryService,
    private dialogService: DialogService
  ) {}

  async downloadCsv(
    fileType: 'single-row' | 'multiple-row',
    particleSizeUnit: string,
    weightTypeTitle: string,
    totalPayload: number,
    deviceName: string,
    psdDataFormatInCSV: 'Default' | 'Single Row' | 'Multiple Rows'
  ) {
    const dateTimeFormat: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      hourCycle: 'h23',
      minute: '2-digit',
      second: '2-digit',
    };

    let input:fragQuery.timeline.sieveSizesAtPercents.Response = null;

    this.fragQuery.timelineSieveSizesAtPercents$.subscribe((data) => {
      if(data){
        input = data;
      }
    })

    if (!input || !input.intervals || !input.percents) {
      console.error('Invalid input data');
      return;
    }else{

      const percents = input.percents.slice().sort((a: number, b: number) => a - b);
      const tsrows: string[] = [];
      const particleSizes = input.summary.psd.length;

      const device = await firstValueFrom(this.fragQuery.device$);
      const customSizes = device?.timelineCustomSizes ? JSON.parse(device.timelineCustomSizes) : [];

      for (let i = 0; i < particleSizes; i++) {
        tsrows.push(`TS${i + 1}`);
        tsrows.push(`PP${i + 1}`);
      }

      const unitSystem = particleSizeUnit as keyof ExtendedTypeMeta;
      const measurementTypeToUnit = new MeasurementTypeToUnit();
      const unit = measurementTypeToUnit.getUnitsForSystem(unitSystem).particleSize;

      const customSizeHeaders = customSizes.map(
        (size: any) => `${measurement.convert(size.size, 'meter', unit).toFixed(2)}${measurement.abbreviation(unit)}`
      );

      const customSizePercentsWithUnit = Object.values(
        input.summary.customSizePercents
      ).map((value) => `${value}%`);

      let csvRows: (string | number)[][] = [];

      const payloadUnitAbbr = await firstValueFrom(this.fragQuery.payloadUnitAbbreviation$);
      const totalPayloadHeader = `Total Payload (${payloadUnitAbbr})`;

      if (psdDataFormatInCSV === 'Single Row') {
        csvRows = [
          [
            'Begin Time',
            'End Time',
            'Total Haulers',
            totalPayloadHeader,
            weightTypeTitle,
            ...percents.map((v: number) => `D${v}`),
            ...tsrows,
            ...customSizeHeaders,
          ],
        ];

        const psdData: number[] = [];
        input.summary.psd.forEach((psd: any, index: number) => {
          const passing_percent = psd.passing_percent ?? (index > 0 ? input.summary.psd[index - 1].passing_percent : 0);
          psd.passing_percent = passing_percent;
          psdData.push(parseFloat(psd.size.toFixed(2)));
          psdData.push(parseFloat(passing_percent.toFixed(2)));
        });

        csvRows.push([
          input.begin.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
          input.end.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
          input.intervals.length,
          totalPayload.toFixed(2),
          input.summary.totalWeight.toFixed(2),
          ...Object.values(input.summary.dvalues).map((value: number) => value.toFixed(2)),
          ...psdData,
          ...customSizePercentsWithUnit,
        ]);
      
      } else if (psdDataFormatInCSV === 'Multiple Rows') {
        csvRows = [
          ['Sr. Number', `Size (${particleSizeUnit})`, 'Target Interval %', 'Cumulative %'],
        ];

        input.summary.psd.forEach((psd: any, i: number, psdArray: any[]) => {
          const targetIntervalPercent = i ? parseFloat((psd.passing_percent - psdArray[i - 1].passing_percent).toFixed(2)) : parseFloat(psd.passing_percent.toFixed(2));

          csvRows.push([
            i + 1,
            psd.size.toFixed(2),
            targetIntervalPercent.toFixed(2),
            psd.passing_percent.toFixed(2),
          ]);
        });

        csvRows.push(['', '', '', '']);

        customSizes.forEach((size: any, index: number) => {
          csvRows.push([
            `${measurement
              .convert(size.size, 'meter', unit)
              .toFixed(2)}${measurement.abbreviation(unit)}`,
            `${Object.values(input.summary.customSizePercents)[index].toFixed(2)}%`,
          ]);
        });

        csvRows.push(['', '', '', '']);

        csvRows.push(
          ['Begin Time', input.begin.toLocaleString(undefined, dateTimeFormat).replace(',', ''), '', ''],
          ['End Time', input.end.toLocaleString(undefined, dateTimeFormat).replace(',', ''), '', ''],
          ['Total Haulers', input.intervals.length, '', ''],
          [totalPayloadHeader, totalPayload.toFixed(2), '', ''],
          [weightTypeTitle, input.summary.totalWeight.toFixed(2), '', '']
        );
      } else {
        csvRows = [
          [
            'Begin Time',
            'End Time',
            ...percents.map((v: number) => `D${v}`),
            ...tsrows,
            ...customSizeHeaders,
          ],
        ];

        input.intervals.forEach((interval: any) => {
          const psdData: number[] = [];
          interval.psd.forEach((psd: any, index: number) => {
            const passing_percent = psd.passing_percent ?? (index > 0 ? interval.psd[index - 1].passing_percent : 0);
            psd.passing_percent = passing_percent;
            psdData.push(parseFloat(psd.size.toFixed(2)));
            psdData.push(parseFloat(passing_percent.toFixed(2)));
          });

          csvRows.push([
            interval.begin.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
            interval.end.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
            ...interval.data.map((v: any) => (typeof v === 'number' ? Math.round(v) : '')),
            ...psdData,
            ...(interval.customSizePercents ?? []).map((value: number) => `${value}%`),
          ]);
        });
      }

      const csvContent = csvRows.map((row) => row.join(',')).join('\n');
      downloadContent(
        cleanFilename(`${deviceName}_report_${new Date().toISOString()}_${psdDataFormatInCSV}`, 'csv'),
        csvContent
      );
    }
    this.dialogService.closeAll()
    }
}
