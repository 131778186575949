import { Component, OnInit } from '@angular/core';
import { User$ } from '@injectables';

@Component({
  selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls:['./auth.component.scss']
})
export class AuthComponent implements OnInit{
  currentYear: number;

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  constructor(public readonly user$:User$){}
}