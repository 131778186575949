import { Component, TemplateRef } from '@angular/core';
import { chart } from '@lib/angular/chart';
import { op } from '@lib/rxjs';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { generateChartLines, generatePayloadChartineForPdf, generateQuantityChartLines } from '../../chart-transforms';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { DXXData, Device, User, fragQuery } from '@models';
import { combineQunatityInput, getSummaryData } from '@app/new-ui/analysis/analysis.component';
import { fragmentationChartOptions } from '../frag-camera-report/frag-camera-report.component';
import { math } from '@lib/math';
import { transformSummary } from '@app/new-ui/shift-analytics/shift-analytics.component';
import { MeasurementTypeToUnit } from '@lib/angular/measurement/measurement-type-to-unit';
import { User$ } from '@injectables';

@Component({
    selector: 'aggregated-data-report',
    templateUrl: './aggregated-data-report.component.html',
    styleUrls: ['./aggregated-data-report.component.scss']
})
export class AggregatedDataReportComponent {

    constructor(
        public fragQuery: FragQueryService,
        public readonly user$: User$,
        private readonly measurementTypeToUnit:MeasurementTypeToUnit
    ) { }

    public selectedDevice: Device;
    public density:number;
    public slabCount: number = 0;
    public slabs:number;
    public slabDiameter:number;
    public quantityUnit:string;
    public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
    public readonly begin$ = this.fragQuery.begin$;
    public readonly end$ = this.fragQuery.end$;
    public readonly device$ = this.fragQuery.device$;
    public readonly shifts$ = this.fragQuery.shifts$;
    public readonly shift$ = this.fragQuery.shift$;
    public readonly timeRange$ = this.fragQuery.timeRange$;
    public readonly duration$ = this.fragQuery.duration$;
    public readonly newPercents$ = this.fragQuery.newPercents$;
    public readonly durationOptions = this.fragQuery.durationOptions;
    public readonly weightType$ = this.fragQuery.weightType$;
    public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;
    public readonly quantityUnitSystem$ = this.fragQuery.QuantityUnitSystem$;
    public readonly totalPayload$ = this.fragQuery.totalPayload$;
    public readonly totalQuantity$ = this.fragQuery.totalWeight$;
    public readonly quantityYAxisTitle$ = this.fragQuery.QuantityTitle$;
    public readonly payloadUnitAbbr$ = this.fragQuery.payloadUnitAbbreviation$;
    public readonly chartType$ = this.fragQuery.chartType$;

    cardTables:TemplateRef<HTMLElement>[]=[];
    tableDataMap: Map<string, {
        dataArr: math.Vec2Like[];
        percentArr: math.Vec2Like[];
        dxxArr: DXXData[];
    }> = new Map();

    public readonly chartLines$: Observable<chart.line.Series[]> = combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.exportTimelineSieveSizesAtPercents$,
    ]).pipe(
        op.debounceTime(0),
        op.map(([percents, input]) => {
            if (input) {
                this.generateTablesData(input, percents);
            }
            return generateChartLines(percents, input);
        }),
        op.shareReplay(1)
    );

    public readonly quantityChartLines$: Observable<chart.line.Series[]> = combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$
    ])
        .pipe(
            op.debounceTime(0),
            op.map(([input, addOn]) => combineQunatityInput(input, addOn, this.fragQuery.addDataAt.value)),
            op.map((input) => generateQuantityChartLines(input)),
            op.shareReplay(1));

    public readonly payloadXAxisTitle$ = this.fragQuery.payloadUnitAbbreviation$.pipe(
        op.map(abbreviation=>{
            return `Payload (${abbreviation})`
        })
    )

    public readonly payloadChartLines$: Observable<chart.line.Series[]> = combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$
    ]) .pipe(
        op.debounceTime(0),
        op.map(([input, addOn]) => combineQunatityInput(input, addOn, this.fragQuery.addDataAt.value)),
        op.map((input) => generatePayloadChartineForPdf(input)),
        op.shareReplay(1));

    public accumulatedData$: Observable<fragQuery.timeline.sieveSizesAtPercents.Interval> = this.fragQuery.timelineSieveSizesAtPercents$.pipe(
        op.map((input) => getSummaryData(input))
    )

    onTablesReceived(tables: TemplateRef<HTMLElement>[]) {
      this.cardTables=tables;
    }

    ngOnInit(): void {
        this.device$.subscribe(device => {
            this.selectedDevice = device;
            this.slabDiameter=device.slabDiameter
            this.density = device.density
        });
        this.fragQuery.timelineSieveSizesAtPercents$.subscribe(data => {
            let totalSlabs = 0;
          
            data.intervals.forEach(interval => {
              if (interval.slabs) {
                totalSlabs += interval.slabs; 
              }
            });
            this.slabs=totalSlabs
        });
    }
    
    isTruck(): boolean {
        return this.selectedDevice && this.selectedDevice.type === 'truck';
    }

    isConveyor(): boolean {
        return this.selectedDevice && this.selectedDevice.type === 'conveyor';
    }

    countSlabs(input: fragQuery.timeline.sieveSizesAtPercents.Response, threshold: number = 12): number {
        if (!input || !input.summary || !input.summary.psd) {
            return 0;
        }
        return input.summary.psd.filter(rock => rock.size > threshold).length;
    }

    generateTablesData(input: fragQuery.timeline.sieveSizesAtPercents.Response, percents: Map<number, string>): { input: fragQuery.timeline.sieveSizesAtPercents.Response, percents: Map<number, string> } {
        if (input === undefined) {
            return undefined;
        }
        let obj = transformSummary(input, percents);
        if (obj) {
            for (const entry of obj.entries()) {
                const [key, value] = entry;
                this.tableDataMap.set(key, fragmentationChartOptions(value.rockSizes, value.rockSizeDetails, value.dxxValues))
            }
        }
        return { input, percents };
    }
}